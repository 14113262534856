export const menu = [
  {
    name: "Pedro Cerna",
    path: "/",
    title: 'Home Page'
  },
  {
    name: "ABOUT",
    path: "/about/",
    title: 'About Me'
  },
  {
    name: "PROJECTS",
    path: "/projects/",
    title: 'My Projects'
  },
  // {
  //   name: "BLOG",
  //   path: "/blog/",
  //   title: 'My Blog Posts'
  // },
  {
    name: "CONTACT",
    path: "/contact/",
    title: 'Contact Me'
  },
]
